import React, { useEffect, useState } from "react";
import "../Styles/cart.css";
import { Link } from "react-router-dom";
import Links from "../Components/HomeComponents/Links";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  handle_delete_cartproducts,
  handlecartquantity,
  handlegetcartproducts,
} from "../Redux/action";
import { toast } from "react-toastify";

function Cart() {
  const user = JSON.parse(sessionStorage.getItem("userdetails"));
  const dispatch = useDispatch();
  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("userdetails"));
    dispatch(handlegetcartproducts(user?._id));
  }, []);
  const store = useSelector((store) => store);
  const { loading, cart, error } = store;

  // console.log(cart)

  const handleremove_product_from_cart = (id) => {
    dispatch(handle_delete_cartproducts(id)).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        toast.success("Product Deleted", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(handlegetcartproducts(user._id));
      } else {
        toast.error("Product Not delete", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const increaseQuantity = (itemId, qty) => {
    const obj = {
      quantity: qty + 1,
      id: user._id,
    };

    // console.log(qty,itemId)
    dispatch(handlecartquantity(itemId, obj)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(handlegetcartproducts(user._id));
        // alert("qty")
      }
    });
  };

  const decreaseQuantity = (itemId, qty) => {
    const obj = {
      quantity: qty - 1,
      id: user._id,
    };

    if (qty === 1) {
      dispatch(handle_delete_cartproducts(itemId)).then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch(handlegetcartproducts(user._id));
          toast.success("Product Removed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
    } else {
      dispatch(handlecartquantity(itemId, obj)).then((res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch(handlegetcartproducts(user?._id));
          // alert("qty")
        }
      });
    }
  };

  const getTotalCost = () => {
    let total = cart.reduce((acc, el) => {
      let productCost = 0;
      // Calculate the cost of the product itself
      if (el.multiple_price.length > 0) {
        for (let i = 0; i < el.multiple_price.length; i++) {
          let w = +el.weight;
          if (w === el.multiple_price[i].weight) {
            productCost += el.multiple_price[i].price * el.quantity;
          }
        }
      } else {
        productCost += el.quantity * Number(el.price);
      }
      // Calculate the cost of addons
      if (el.addons && el.addons.length > 0) {
        el.addons.forEach((addon) => {
          productCost += Number(addon.price);
        });
      }
      // Add the product cost to the total
      acc += productCost;
      return acc;
    }, 0);
  
    // Patch total to backend
    let obj = { total: total || "0" };
    let user = JSON.parse(sessionStorage.getItem("userdetails"));
    axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/total/${user?._id}`, obj)
      .then((res) => {
        // console.log(res)
      });
    return total;
  };
  

  return cart?.length > 0 ? (
    <>
      {/* <div style={{ background: "#f7f9fc" }}>
        <h2 style={{ borderBottom: "2px solid white", display: "inline" }}>
          Cart
        </h2>
        <div className="cart">
          <div style={{ height: "400px", overflowY: "auto" }}>
            {cart.map((item) => (
              <div className="cart-item" key={item.id}>
                <div className="cartContainer">
                  <div>
                    <img
                      // style={{ height: "auto", width: "55%" }}
                      src={item.image[0]}
                      alt=""
                    />
                  </div>
                  <div className="details">
                    <h3 className="cart-prod-name"> {item.name}</h3>
                    <p>₹{item.price}</p>
                    <p>{item.multiple_price.length?item.price:`₹${item.price}`}</p>
                  </div>
                </div>
                <div className="quantity">
                  <button
                    onClick={() =>
                      decreaseQuantity(item?.productID, item.quantity)
                    }
                  >
                    -
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    onClick={() =>
                      increaseQuantity(item?.productID, item.quantity)
                    }
                  >
                    +
                  </button>
                </div>
                <div
                  className="remove"
                  onClick={() =>
                    handleremove_product_from_cart(item?.productID)
                  }
                >
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/3687/3687412.png"
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="checkout">
            <h3>Checkout</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
                margin: "auto",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
              }}
            >
              <p>SubTotal: </p>
              <p>₹{getTotalCost()}</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "50%",
                margin: "auto",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
              }}
            >
              <p>Total: </p>
              <p>₹ {getTotalCost()}</p>
            </div>

            <Link to="/checkout" style={{ textDecoration: "none" }}>
              <button style={{ padding: "10px", cursor: "pointer" }}>
                Proceed to Checkout
              </button>
            </Link>
            <div
              className="apply-cont"
              style={{
                width: "50%",
                margin: "auto",
                marginBottom: "50px",
                marginTop: "50px",
              }}
            >
              <p>Coupon Code</p>
              <input type="text" placeholder="Enter your coupon code" />
              <button
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  marginTop: "20px",
                }}
                className="apply-button"
              >
                Apply Coupon
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <section>
        <div class="mx-auto max-w-screen-xl px-2 py-8 sm:px-4 sm:py-12 lg:px-4">
          <div class="mx-auto max-w-5xl">
            <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1>

            <div class="mt-8">
              <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mb-4 w-full">
                <div className="rounded-lg ">
                  <ul class="space-y-4">
                    {cart.map((item) => (
                      <li class="flex items-center gap-4" key={item.id}>
                        <img
                          src={item.image[0]}
                          alt=""
                          className="size-24 rounded object-cover"
                        />

                        <div>
                          <h3 class="text-xl text-gray-900">{item.name}</h3>

                          <dl class="mt-0.5 space-y-px text-[18px] text-gray-600">
                            <div>
                              <dt class="inline">Price:</dt>
                              <dd class="inline">₹{item.price}</dd>
                            </div>

                            <div>
                              <dt class="inline">Color:</dt>
                              <dd class="inline">White</dd>
                            </div>
                          </dl>
                        </div>

                        <div class="flex flex-1 items-center justify-end gap-2">
                          <div>
                            <label for="Quantity" class="sr-only">
                              {" "}
                              {item.quantity}{" "}
                            </label>

                            <div class="flex items-center gap-1">
                              <button
                                type="button"
                                class="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                                onClick={() =>
                                  decreaseQuantity(
                                    item?.productID,
                                    item.quantity
                                  )
                                }
                              >
                                &#8722;
                              </button>

                              <input
                                type="number"
                                id="Quantity"
                                value={item?.quantity}
                                class="h-10 w-16 rounded border-gray-200 text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                              />

                              <button
                                type="button"
                                class="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                                onClick={() =>
                                  increaseQuantity(
                                    item?.productID,
                                    item.quantity
                                  )
                                }
                              >
                                &#43;
                              </button>
                            </div>
                          </div>

                          <button
                            class="text-gray-600 transition hover:text-red-600"
                            onClick={() =>
                              handleremove_product_from_cart(item?.productID)
                            }
                          >
                            <span class="sr-only">Remove item</span>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="h-4 w-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      </li>
                    ))}{" "}
                  </ul>
                </div>
                <div className="rounded-lg ">
                  {" "}
                  <div class="mt-8 flex justify-end border-t border-gray-100 pt-8">
                    <div class="w-screen max-w-lg space-y-4">
                      <dl class="space-y-0.5 text-sm text-gray-700">
                        <div class="flex justify-between">
                          <dt>Subtotal</dt>
                          <dd>₹{getTotalCost()}</dd>
                        </div>

                        <div class="flex justify-between">
        <dt>VAT</dt>
        <dd>₹25</dd>
      </div>

                        <div class="flex justify-between">
                          <dt>Discount</dt>
                          <dd>-₹20</dd>
                        </div>

                        <div class="flex justify-between !text-base font-medium">
                          <dt>Total</dt>
                          <dd>₹{getTotalCost()}</dd>
                        </div>
                      </dl>

                      <div class="flex justify-end">
                        <span class="inline-flex items-center justify-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-indigo-700">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="-ms-1 me-1.5 h-4 w-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                            />
                          </svg>

                          <p class="whitespace-nowrap text-xs">
                            Discounts Applied
                          </p>
                        </span>
                      </div>

                      <div class="flex justify-end">
                        <a
                          href="/checkout"
                          class="block rounded bg-gray-700 px-5 py-3 text-sm text-gray-100 transition hover:bg-gray-300"
                        >
                          Checkout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div className="bg-gray-100 pt-10 pb-10">
        <h1 className="mb-10 text-center text-2xl font-bold">Cart Items</h1>
        <div className="mx-auto max-w-5xl justify-center px-6 md:flex md:space-x-6 xl:px-0">
          <div className="rounded-lg md:w-2/3">
            {cart?.map((product) => (
              <div
                key={product.id}
                className="justify-between mb-6 rounded-lg bg-white p-6 shadow-sm sm:flex sm:justify-start"
              >
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full rounded-lg sm:w-40"
                />
                <div className="sm:ml-4 sm:flex sm:w-full sm:justify-between">
                  <div className="mt-5 sm:mt-0">
                    <h3 className="text-md font-bold text-gray-900">
                      {product.name}
                    </h3>
                    <p className="mt-1 text-xs text-gray-700">
                      {product?.desc}
                    </p>
                    <div className="flex items-center space-x-4">
                      <p className="text-md">
                        Price :{" "}
                        {product.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </p>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                    <div className="flex items-center border-gray-100">
                      <div class="flex flex-1 items-center justify-end gap-2">
                        <div>
                          <label for="Quantity" class="sr-only">
                            {" "}
                            {product.quantity}{" "}
                          </label>

                          <div class="flex items-center gap-1">
                            <button
                              type="button"
                              class="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                              onClick={() =>
                                decreaseQuantity(
                                  product?.productID,
                                  product.quantity
                                )
                              }
                            >
                              &#8722;
                            </button>

                            <input
                              type="number"
                              id="Quantity"
                              value={product?.quantity}
                              class="h-10 w-16 rounded border-gray-200 text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none"
                            />

                            <button
                              type="button"
                              class="size-10 leading-10 text-gray-600 transition hover:opacity-75"
                              onClick={() =>
                                increaseQuantity(
                                  product?.productID,
                                  product.quantity
                                )
                              }
                            >
                              &#43;
                            </button>
                          </div>
                        </div>

                        <button
                          class="text-gray-600 transition hover:text-red-600"
                          onClick={() =>
                            handleremove_product_from_cart(product?.productID)
                          }
                        >
                          <span class="sr-only">Remove item</span>

                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-4 w-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    {/* <div className="flex items-center space-x-4">
                      <p className="text-sm">
                        {product.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </p>
                      
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 md:w-1/3">
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Subtotal</p>
              <p className="text-gray-700">
                {getTotalCost().toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </p>
            </div>
            {/* {isCouponApplied && (
            <div className="flex justify-between">
              <p className="text-gray-700">
                Discount ({appliedCoupon || "Applied"})
              </p>
              <p className="text-gray-700">
                {discount.toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })}
              </p>
            </div>
          )} */}
            <div className="flex justify-between">
              <p className="text-gray-700">GST</p>
              <p className="text-gray-700">0</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-700">Shipment</p>
              <p className="text-gray-700">00</p>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between">
              <p className="text-lg font-bold">Total</p>
              <div className="">
                <p className="mb-1 text-lg font-bold">
                  {/* {(subtotal - discount + 4.99).toLocaleString("en-IN", {
                  style: "currency",
                  currency: "INR",
                })} */}
                  {getTotalCost().toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </p>
                {/* <p className="text-sm text-gray-700">including VAT</p> */}
              </div>
            </div>

            {/* <button
              type="button"
              className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-2"
            >
              Checkout
            </button> */}
            <Link to="/checkout">
              <button
                type="button"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-2"
              >
                Checkout
              </button>
              </Link>
          </div>
        </div>
      </div>

      <Links />
    </>
  ) : (
    <div style={{ width: "50%", margin: "auto" }}>
      <img
        width={"80%"}
        style={{ marginTop: "50px", height: "100%" }}
        src="https://www.clickbazar.com/Images/empty-cart.gif"
        alt=""
      />
    </div>
  );
}

export default Cart;
