import React from "react";

const ShippingandDelivery = () => {
  return (
    <div className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white">
      <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg">
          <header className="mb-4 lg:mb-6 not-format">
            <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">
              Shipping and Delivery
            </h1>
            <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
              <div>
                <p className="text-base text-gray-500 dark:text-gray-400">
                  <time pubdate datetime="2024-03-13" title="March 13th, 2024">
                    Last updated on Mar 13 2024
                  </time>
                </p>
              </div>
            </div>
          </header>
          <p className="lead py-2">
            For International buyers, orders are shipped and delivered through
            registered international courier companies and/or International
            speed post only. For domestic buyers, orders are shipped through
            registered domestic courier companies and /or speed post only.
            
          </p>
          
          
          <p className="py-2">
          Orders are shipped within 0-7 days or as per the delivery date
            agreed at the time of order confirmation and delivering of the
            shipment subject to Courier Company / post office norms.
            
          </p>

          <p>
          JoyFulXperience is not liable for any delay in delivery by the
            courier company / postal authorities and only guarantees to hand
            over the consignment to the courier company or postal authorities
            within 0-7 days rom the date of the order and payment or as per the
            delivery date agreed at the time of order confirmation. 
          </p>

          <p>
          Delivery of
            all orders will be to the address provided by the buyer. Delivery of
            our services will be confirmed on your mail ID as specified during
            registration. 
          </p>
          <p>
          For any issues in utilizing our services you may
            contact our helpdesk on 8595021905 or theromeodotin@gmail.com
          </p>

        </article>
      </div>
    </div>
  );
};

export default ShippingandDelivery;
