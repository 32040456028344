import React, { useRef } from "react";
// import './AddressPage.css';
// import "../Styles/shipping.css";
import { useDispatch } from "react-redux";
import { handleaddress } from "../Redux/action";
import { toast } from "react-toastify";
function AddressPage({ handlesavaddress }) {
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("userdetails"));
  const userdet = useRef({});
  const handle_address_update = (e) => {
    e.preventDefault();
    let houseno = userdet.current["houseno"].value;
    let street = userdet.current["street"].value;
    let city = userdet.current["city"].value;
    let state = userdet.current["state"].value;
    let country = userdet.current["country"].value;
    let postalcode = userdet.current["postalcode"].value;

    let address = {
      houseNo: houseno,
      street: street,
      city: city,
      state: state,
      country: country,
      zipCode: postalcode,
    };

    let obj = { address };

    console.log(user);
    dispatch(handleaddress(user?._id, obj)).then((res) => {
      if (res.status === 201 || res.status === 200) {
        toast.success("Address Saved", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handlesavaddress();
      } else {
        toast.error("Some Error Please retry", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  return (
    <div className="justify-between mb-6 rounded-lg bg-white p-6 shadow-sm sm:justify-start">
      <h3 className="text-lg font-semibold mb-4">Shipping Address</h3>
      <form className="space-y-4" action="" onSubmit={handle_address_update}>
        <div>
          <label
            htmlFor="fullName"
            className="block text-sm font-medium text-gray-700"
          >
            Full Name
          </label>
          <input
            type="text"
            name="fullName"
            id="fullName"
            className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Full Name"
          />
        </div>

        <div>
          <label
            htmlFor="house no"
            className="block text-sm font-medium text-gray-700"
          >
            Address Line
          </label>
          <input
            type="text"
            name="houseno"
            id="houseno"
            ref={(e) => (userdet.current["houseno"] = e)}
            className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
            placeholder="Apartment, studio, or floor"
          />
        </div>
        <div>
          <label
            htmlFor="street"
            className="block text-sm font-medium text-gray-700"
          >
            Street
          </label>
          <input
            type="text"
            name="street"
            id="street"
            placeholder="Street"
            ref={(e) => (userdet.current["street"] = e)}
            className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>

        <div>
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            City
          </label>
          <input
            type="text"
            id="city"
            placeholder="City"
            className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
            ref={(e) => (userdet.current["city"] = e)}
          />
        </div>
        <div className="md:flex md:space-x-4">
          <div className="md:w-1/2">
            <label
              htmlFor="state"
              className="block text-sm font-medium text-gray-700"
            >
              State
            </label>
            <input
              type="text"
              id="state"
              placeholder="State"
              ref={(e) => (userdet.current["state"] = e)}
              className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>

          <div className="md:w-1/2">
            <label
              htmlFor="postalCode"
              className="block text-sm font-medium text-gray-700"
            >
              Postal Code
            </label>
            <input
              type="text"
              id="postalCode"
              ref={(e) => (userdet.current["postalcode"] = e)}
              className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Postal Code"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700"
          >
            Country
          </label>
          <input
            type="text"
            id="country"
            placeholder="Country"
            ref={(e) => (userdet.current["country"] = e)}
            className="w-full mb-2 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
          />
        </div>
        <button className="w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-2">
          Save Address
        </button>
      </form>
    </div>
  );
}

export default AddressPage;
