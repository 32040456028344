import React from "react";

const CancellationandRefund = () => {
  return (
    <div className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white">
      <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg">
          <header className="mb-4 lg:mb-6 not-format">
            <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">
              Cancellation and Refund
            </h1>
            <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
              <div>
                <p className="text-base text-gray-500 dark:text-gray-400">
                  <time pubdate datetime="2024-03-13" title="March 13th, 2024">
                    Last updated on Mar 13 2024
                  </time>
                </p>
              </div>
            </div>
          </header>
          <p className="lead py-2">
            JoyFulXperience believes in helping its customers as far as
            possible, and has therefore a liberal cancellation policy. Under
            this policy:
          </p>
          <p className="py-2">
            Cancellations will be considered only if the request is made within
            same day of placing the order. However, the cancellation request may
            not be entertained if the orders have been communicated to the
            vendors/merchants and they have initiated the process of shipping
            them.
          </p>
          <p className="py-2">
            JoyFulXperience does not accept cancellation requests for perishable
            items like flowers, eatables etc. However, refund/replacement can be
            made if the customer establishes that the quality of product
            delivered is not good.
          </p>

          <p className="py-2">
            In case of receipt of damaged or defective items please report the
            same to our Customer Service team. The request will, however, be
            entertained once the merchant has checked and determined the same at
            his own end. This should be reported within same day of receipt of
            the products.
          </p>
          <p className="py-2">
            In case you feel that the product received is not as shown on the
            site or as per your expectations, you must bring it to the notice of
            our customer service within same day of receiving the product. The
            Customer Service Team after looking into your complaint will take an
            appropriate decision.
          </p>
          <p className="py-2">
            In case of complaints regarding products that come with a warranty
            from manufacturers, please refer the issue to them.
          </p>
          <p className="py-2">
            In case of any Refunds approved by the JoyFulXperience, it’ll take
            6-8 days for the refund to be processed to the end customer.
          </p>
        </article>
      </div>
    </div>
  );
};

export default CancellationandRefund;
