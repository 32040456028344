import React, { useEffect, useState } from "react";
import "./category2.css";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const Categories2 = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cityname = searchParams.get("city");
  const [city, setCity] = useState(cityname || "delhi");
  useEffect(() => {
    let params = {
      city,
    };
    setSearchParams(params);
  }, [city]);
  const cards = [
    {
      category: "Kids Birthday Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953452_small.jpg",
    },
    {
      category: "Bacholorette Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953479_small.jpg",
    },
    {
      category: "Baby Welcome Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953613_small.jpg",
    },
    {
      category: "Anniversary Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953601_small.jpg",
    },
    {
      category: "Baby Shower",
      img: "https://cdn.cherishx.com/uploads/1699953463_small.jpg",
    },
    {
      category: "Car Booth Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953564_small.jpg",
    },
    {
      category: "Stage Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953498_small.jpg",
    },
    {
      category: "Naming Celebration Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953591_small.jpg",
    },
    {
      category: "Personalized Gifts",
      img: "https://cdn.cherishx.com/uploads/1699953506_small.jpg",
    },
    {
      category: "First Night Decoration",
      img: "https://cdn.cherishx.com/uploads/1699953577_small.jpg",
    },
  ];

  return (
    <div className="category-cont">
      {cards.map((el) => {
        return (
          <div>
            <Link to={`products?city=${cityname}&category=${el.category}`}>
              <img className="cat2-img" src={el.img} alt="" />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Categories2;
