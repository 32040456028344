import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Heart } from "lucide-react";

const BookehCard = ({ id, img, name, desc, price, heading }) => {
  // console.log(multiple_price);
  const handlepage = () => {
    window.scrollTo(0, 0);
  };

  const [clicked, setClicked] = useState(false);

  const handleWishlist = () => {
    setClicked(!clicked);
    if (clicked) {
      localStorage.removeItem(id);
    } else {
      localStorage.setItem(id, JSON.stringify({ id, img, name, desc, price }));
    }
  };
  return (
    <div className="relative m-2 border p-3 bg-white rounded-md">
      <div
        className="absolute right-2 top-2 z-10 p-2 transition hover:text-gray-900/75 bg-transparent cursor-pointer"
        onClick={handleWishlist}
      >
        <span className="sr-only">Wishlist</span>
        {clicked ? (
          <Heart size={24} fill="red" />
        ) : (
          <Heart size={24} color="white" />
        )}
      </div>
      <Link
        to={`/products/${id}`}
        style={{ textDecoration: "none" }}
        className="group relative block overflow-hidden "
        onClick={handlepage}
      >
        <div>
          <img
            src={img}
            alt=""
            className="transition duration-500 group-hover:scale-105  object-cover object-center aspect-square"
          />
          <div className="mt-4 flex justify-between">
            <div>
              <h3 className="text-sm text-gray-700">
                <a href="#">
                  <span aria-hidden="true" class="absolute inset-0"></span>
                  {name?.slice(0, 23)}
                </a>
              </h3>
              <p className="mt-1 text-sm text-gray-500">{desc}</p>
            </div>
            <p className="text-sm font-medium text-gray-900">₹{price}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BookehCard;
