import React from "react";
import "./whatsapp.css";
const Whatsapp = () => {
  return (
    <div>
      <button id="whatsapp">
        <a href="https://wa.link/2gz9gl">
          <img
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            src="https://cdn-icons-png.flaticon.com/512/124/124034.png?w=360"
            alt=""
          />
        </a>
      </button>
    </div>
  );
};

export default Whatsapp;
