import React from "react";

const TermsandCondition = () => {
  return (
    <div className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white">
      <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg">
          <header className="mb-4 lg:mb-6 not-format">
            <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl">
              Terms and Conditions
            </h1>
            <div className="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
              <div>
                <p className="text-base text-gray-500 dark:text-gray-400">
                  <time pubdate datetime="2024-03-13" title="March 13th, 2024">
                    Last updated on Mar 13 2024
                  </time>
                </p>
              </div>
            </div>
          </header>
          <p className="lead py-2">
            For the purpose of these Terms and Conditions, The term "we", "us",
            "our" used anywhere on this page shall mean JoyFulXperience, whose
            registered/operational office is D499, Ramhal Chowk South West Delhi
            DELHI 110075 . "you", “your”, "user", “visitor” shall mean any
            natural or legal person who is visiting our website and/or agreed to
            purchase from us.
          </p>
          <p className="py-2">
            Your use of the website and/or purchase from us are governed by
            following Terms and Conditions:
          </p>
          <p className="py-2">
            The content of the pages of this website is subject to change
            without notice.
          </p>

          <p className="py-2">
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </p>
          <p className="py-2">
            Your use of any information or materials on our website and/or
            product pages is entirely at your own risk, for which we shall not
            be liable. It shall be your own responsibility to ensure that any
            products, services or information available through our website
            and/or product pages meet your specific requirements.
          </p>
          <p className="py-2">
            Our website contains material which is owned by or licensed to us.
            This material includes, but are not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </p>
          <p className="py-2">
            All trademarks reproduced in our website which are not the property
            of, or licensed to, the operator are acknowledged on the website.
          </p>

          <p>
            Unauthorized use of information provided by us shall give rise to a
            claim for damages and/or be a criminal offense.
          </p>

          <p>
            From time to time our website may also include links to other
            websites. These links are provided for your convenience to provide
            further information.
          </p>
          <p>
            You may not create a link to our website from another website or
            document without JoyFulXperience’s prior written consent.
          </p>

          <p>
            Any dispute arising out of use of our website and/or purchase with
            us and/or any engagement with us is subject to the laws of India .
          </p>
          <p className="py-2">
            We, shall be under no liability whatsoever in respect of any loss or
            damage arising directly or indirectly out of the decline of
            authorization for any Transaction, on Account of the Cardholder
            having exceeded the preset limit mutually agreed by us with our
            acquiring bank from time to time
          </p>
        </article>
      </div>
    </div>
  );
};

export default TermsandCondition;
