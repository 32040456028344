import React, { useEffect, useState } from "react";
import "./categories.css";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
const Categories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cityname = searchParams.get("city");
  const [city, setCity] = useState(cityname || "delhi");
  useEffect(() => {
    let params = {
      city,
    };
    setSearchParams(params);
  }, [city]);
  return (
    <div className="cont-cat">
      <div>
        <Link to={`products?city=${cityname}&category=Party Decorations`}>
          <img
            src="https://cdn.cherishx.com/uploads/1700218246_small.jpg"
            alt=""
          />
        </Link>
      </div>
      <div>
        <Link to={`products?city=${cityname}&category=Candlelight Dinner`}>
          <img
            src="https://cdn.cherishx.com/uploads/1700218383_small.jpg"
            alt=""
          />
        </Link>
      </div>
      <div className="cont-cat-3">
        <Link to={`products?city=${cityname}&category=Holi Special`}>
          <img
            src="https://cdn.cherishx.com/uploads/1710500986_small.jpg"
            alt=""
          />
        </Link>
      </div>
    </div>
  );
};

export default Categories;
