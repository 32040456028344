import * as React from "react";
import "./modal.css";
import { useEffect, useState } from "react";
import { Box, Button, Modal, Text, useDisclosure } from "@chakra-ui/react";
import {
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { MapPinIcon } from "lucide-react";
export default function BasicModal({ name }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialCity = searchParams.get("city");
  const [city, setCity] = useState(initialCity || "delhi");
  const initialCategory = searchParams.get("category");

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [status, setStatus] = useState(true);
  const cities = [
    {
      name: "Delhi",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Delhi_Icon-min.png",
      id: 1,
    },
    {
      name: "Jaipur",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Jaipur_Icon-min.png",
      id: 2,
    },
    {
      name: "Bangalore",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Bangalore_Icon-min.png",
      id: 3,
    },
    {
      name: "kolkata",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Kolkata_Icon-min.png",
      id: 4,
    },
    {
      name: "Indore",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Indore_Icon-min.png",
      id: 5,
    },
    {
      name: "Pune",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Pune_Icon-min.png",
      id: 6,
    },
    {
      name: "All India",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/india-icon-min.png",
      id: 7,
    },
    {
      name: "Hyderabad",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Hyderabad_min.png",
      id: 8,
    },
    {
      name: "Mumbai",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Mumbai_min.png",
      id: 9,
    },
    {
      name: "Kanpur",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Kanpur_min.png",
      id: 10,
    },
    {
      name: "chennai",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Chennai_min.png",
      id: 11,
    },
    {
      name: "Jammu",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Jammu_min.png",
      id: 12,
    },
    {
      name: "Lucknow",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Lucknow_min.png",
      id: 13,
    },
    {
      name: "Chandigarh",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Chandigarh_min.png",
      id: 14,
    },
    {
      name: "Ahmedabad",
      img: "https://deowgxgt4vwfe.cloudfront.net/city-icons/Ahmedabad_min.jpg",
      id: 15,
    },
  ];

  useEffect(() => {
    const storedCity = sessionStorage.getItem("cityname");
    if (!storedCity) {
      onOpen();
    } else {
      setCity(storedCity);
      setStatus(false);
    }
  }, []);

  const handleClose = (selectedCity) => {
    sessionStorage.setItem("cityname", selectedCity);
    sessionStorage.setItem("status", "false");
    onClose();
  };

  const handleSelectCity = (selectedCity) => {
    setCity(selectedCity);
  };

  useEffect(() => {
    let params = {
      city,
    };
    if (initialCategory) {
      params.category = initialCategory;
    }
    setSearchParams(params);
  }, [city, initialCategory]);

  return (
    <div className="flex items-center space-x-4">
      <Button
        style={{
          padding: "10px",
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none",
          fontSize: "16px",
          color: "black",
          display: "flex",
        }}
        className="px-4 py-2 bg-transparent border:none border-gray-100 text-gray-700 font-medium text-lg rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 "
        onClick={onOpen}
      >
        <MapPinIcon size={20} color="black" className="-ml-1 mr-2" />
        {city.toUpperCase()}
      </Button>
      <Modal
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="p-4">
            <Box className="city-modal">
              <Text
                marginBottom={"30px"}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                SELECT YOUR CITY
              </Text>
              <hr />
              <p id="desc" style={{ textAlign: "center", marginTop: "10px" }}>
                Find more than 3000 decorations, gifts and surprises!
              </p>
              <Text id="modal-modal-description" sx={{ mt: 2 }}>
                <Box className={"cities-cont"}>
                  {cities.map((city, index) => (
                    <div
                      onClick={() => {
                        handleClose(city.name);
                        handleSelectCity(city.name);
                      }}
                      className="cities"
                      key={index}
                    >
                      <img
                        className="modal-img"
                        src={city.img}
                        alt={city.name}
                      />
                      <p>{city.name.toUpperCase()}</p>
                    </div>
                  ))}
                </Box>
                <hr style={{ marginTop: "40px" }} />
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
