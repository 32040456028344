import React, { useEffect, useRef, useState } from "react";
import "../Styles/navbar.css";
import { Link } from "react-router-dom";
import BasicModal from "../Components/HomeComponents/Modal";
import TemporaryDrawer from "../Components/HomeComponents/DrawerComp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handlegetcartproducts, handleLogout } from "../Redux/action";
import logo from "./logo3.png";
import { useSearchParams } from "react-router-dom";
import SearchResults from "./SearchResults";
import Swal from "sweetalert2";
import { CircleUserRound, LogOutIcon, Search, ShoppingBag } from "lucide-react";
function Navbar({ cartcount }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialCity = sessionStorage.getItem("cityname")?.toLowerCase();
  // const [selectedProduct, setSelectedProduct] = useState(null);
  const searchResultsRef = useRef(null);
  const store = useSelector((store) => store);
  const { token, cart } = store;
  const [menuOpen, setMenuOpen] = useState(false);
  const [flag, setflag] = useState(false);
  const navigate = useNavigate();
  const [items, setitems] = useState(cart.length);
  const [text, setText] = useState("");
  const [results, setResults] = useState([]);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAddClassname = () => {
    setflag(false);
    setText("");
  };
  const handlelogout = () => {
    sessionStorage.setItem("token", "");

    // sessionStorage.removeItem('userdetails'); // Remove user details
    // Cookies.remove('token'); // Remove token (if applicable)
    // sessionStorage.setItem("userdetails", "");

    Swal.fire("😶", "Logut Successfully", "success");
    setTimeout(() => {
      window.location.reload();
    }, [1000]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setResults([]); // Clear search results when clicking outside
      }
    };

    // Add event listener to window
    window.addEventListener("click", handleClickOutside);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setitems(cart?.length);
    setSearchParams({ city: initialCity });
  }, [cart, initialCity, flag]);

  const handlevalue = (e) => {
    setText(e.target.value);
    // setflag(false)
  };

  function handleSearchClick() {
    setflag(true);
    if (text.trim() !== "") {
      fetch(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/products/search?q=${encodeURIComponent(text)}`
      )
        .then((response) => response.json())
        .then((data) => {
          const searchResults = data.results;
          setResults(searchResults);
        })
        .catch((error) => {
          console.error("Error fetching search results:", error);
        });
    }
  }
  return (
    <>
      <div className="flex items-center justify-between h-20 px-2 bg-gray-100/40 text-gray-800">
        <div className="flex flex-row items-center">
          <div className="items-center block sm:hidden md:hidden lg:hidden xl:hidden">
            <TemporaryDrawer />
          </div>

          <div className="flex items-center">
            <Link to={`/?city=${initialCity}`}>
              <img
                className="object-cover h-24 md:h-16 w-auto md:w-24 mx-auto"
                src="JFX Logo.png"
                alt="JFX Logo"
              />
            </Link>

            <div className="relative ml-4">
              <input
                type="text"
                className="peer relative h-10 w-full rounded border border-slate-200 px-4 pr-12 text-sm text-slate-500 outline-none transition-all autofill:bg-white focus:border-blue-500 focus:outline-none invalid:focus:border-pink-500 focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-slate-50 disabled:text-slate-400"
                placeholder="Search"
                onChange={handlevalue}
                value={text}
              />
              <button
                className="absolute right-4 top-1/2 transform -translate-y-1/2 peer-disabled:cursor-not-allowed"
                onClick={handleSearchClick}
              >
                <Search size={24} color="gray" />
              </button>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
  <div className="ms-4">
    {token === "" ? (
      <Link
        to={"/login"}
        className="px-3 py-2 rounded-md text-gray-700 md:block hidden" // Login button visible only on desktop
      >
        <CircleUserRound size={24} color="black" />
      </Link>
    ) : (
      <Link
        to={"/login"}
        className="px-3 py-2 rounded-md text-gray-700 md:block hidden" // Logout button visible only on desktop
        onClick={handlelogout}
      >
        <LogOutIcon size={24} color="black" />
      </Link>
    )}
  </div>

  {/* Hide cart link on mobile */}
  {/* <Link to={"/cart"} className="text-white hover:text-gray-400 md:block hidden"> // Commented out if not needed */}
  

  <div className="relative hidden sm:block">
    <Link to={"/cart"} className="text-white hover:text-gray-400">
      <ShoppingBag size={24} color="black" />
    </Link>
    {items > 0 && (
      <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
        {items}
      </span>
    )}
  </div>

  <div className="center relative inline-block select-none whitespace-nowrap rounded-lg bg-gradient-to-tr from-gray-200 to-gray-300 font-bold text-white">
    <BasicModal name={"delhi"} /> {/* Location modal always visible */}
  </div>
</div>

        {flag && (
          <div id="search-results" ref={searchResultsRef}>
            {results.map((result) => (
              <Link key={result._id} to={`/products/${result._id}`}>
                <div
                  className="search-result-item"
                  onClick={handleAddClassname}
                >
                  {result.name}
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Navbar;
