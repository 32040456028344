import React, { useState, useEffect } from "react";
import "./login.css";
import Swal from "sweetalert2";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin, handleSignup } from "../Redux/action";
import { Hearts } from "react-loader-spinner";
import { Navigate, useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import Aws from "../Components/ProductComponents/Aws";

function Login() {
  const [showLogin, setShowLogin] = useState(true);
  const [otp, setOtp] = useState("");
  const [username, setusername] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [confirmationResult, setConfirmationResult] = useState(null); // New state variable for confirmationResult
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((store) => store);
  const { loading, error, token } = store;

  const getOtp = (e) => {
    e.preventDefault();
  
    const auth = getAuth();
    let appVerifier;
  
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
    }
  
    appVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
      size: "invisible",
    });
    window.recaptchaVerifier = appVerifier;
  
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((result) => {
        // SMS sent. Prompt user to type the code from the message.
        setConfirmationResult(result);
      }).catch((error) => {
        // Error; SMS not sent
        console.error(error);
      });
  };
  const handleRegister = (e) => {
    e.preventDefault();

    if (confirmationResult) {
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          console.log(user);

          let obj = {
            name: username,
            email: email,
            password: password,
            phoneNumber: phoneNumber,
          };

          dispatch(handleSignup(obj)).then((res) => {
            if (res.status === 200 || res.status === 201) {
              Swal.fire("Good job!", "Signup Successful", "success");
              return navigate("/");
            } else {
              // Handle error
            }
          });
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.error(error);
          Swal.fire("Error", "Invalid verification code", "error");
        });
    }
  };

  // const handleRegister = (e) => {
  //   e.preventDefault();

  //   const appVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
  //     'size': 'invisible',
  //     'callback': (response) => {
  //       // reCAPTCHA solved, allow signInWithPhoneNumber.
  //       // ...

  //     }
  //   });
  //   signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  //     .then((confirmationResult) => {
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code).
  //       confirmationResult.confirm(otp).then((result) => { // Use otp state variable instead of window.prompt
  //         // User signed in successfully.
  //         const user = result.user;
  //         console.log(user);

  //         let obj = {
  //           name: username,
  //           email: email,
  //           password: password,
  //           phoneNumber: phoneNumber,
  //         };
  //         dispatch(handleSignup(obj)).then((res) => {
  //           if (res.status === 200 || res.status === 201) {
  //             Swal.fire("Good job!", "Signup Successful", "success");
  //             return navigate("/");
  //           } else {
  //             // Handle error
  //           }
  //         });
  //       }).catch((error) => {
  //         // User couldn't sign in (bad verification code?)
  //         console.error(error);
  //       });
  //     }).catch((error) => {
  //       // Error; SMS not sent
  //       console.error(error);
  //     });
  // };

  // const handleRegister = (e) => {
  //   e.preventDefault();
  //   let obj = {
  //     name: username,
  //     email: email,
  //     password: password,
  //     phoneNumber: phoneNumber,
  //   };

  //   dispatch(handleSignup(obj)).then((res) => {
  //     // console.log(res.response.status);
  //     if (res.status === 200 || res.status === 201) {
  //       Swal.fire("Good job!", "Signup Successful", "success");
  //       return navigate("/");
  //     } else {
  //       Swal.fire(
  //         "Enter valid Email or User Already Exists",
  //         "couldn't signup",
  //         "error"
  //       );
  //       return navigate("/login");
  //     }
  //   });
  // };

  const handlelogin = (e) => {
    e.preventDefault();
    let obj = {
      email: email,
      password: password,
    };

    dispatch(handleLogin(obj))
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          Swal.fire("Good job!", "You have successfully logged in", "success");
          return navigate("/");
        } else {
          Swal.fire("Email or Password Wrong", "couldn't log in", "error");
          return navigate("/login");
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  if (token) {
    return <Navigate to="/" />;
  }

  const handleToggle = () => {
    setShowLogin(!showLogin);
  };

  return (
    <div className="container">
      <CSSTransition
        in={showLogin}
        timeout={300}
        classNames="form-transition"
        unmountOnExit
      >
        <div className="form-container">
          <h2>Login</h2>
          <form onSubmit={handlelogin}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                required
              />
            </div>
            <button className="registerbtn" type="submit">
              Login
            </button>
            <p className="toggle-text">
              Don't have an account?{" "}
              <span className="toggle-link" onClick={handleToggle}>
                Sign Up
              </span>
            </p>
          </form>
        </div>
      </CSSTransition>
      <CSSTransition
        in={!showLogin}
        timeout={300}
        classNames="form-transition"
        unmountOnExit
      >
        <div className="form-container">
          <h2>Sign Up</h2>
          <form onSubmit={handleRegister}>
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setusername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setpassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required // Add a pattern attribute for basic validation // Error message for invalid input
              />
            </div>

            <div className="flex items-center my-4 space-x-4">
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
              />
              <button
                type="button"
                onClick={getOtp}
                className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 disabled:cursor-not-allowed disabled:border-blue-300 disabled:bg-blue-300 disabled:shadow-none"
              >
                <span>Get OTP</span>
              </button>
            </div>

            <button className="registerbtn" type="submit" disabled={loading}>
              Register
            </button>
            <p className="toggle-text">
              Already have an account?{" "}
              <span className="toggle-link link-" onClick={handleToggle}>
                Login
              </span>
            </p>
          </form>
          <div
            id="recaptcha-container"
            className="g-recaptcha"
            data-sitekey="6Lesr7IpAAAAAHNjxw9a0b635CA0EBuS-cm_Cc8m"
          ></div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Login;
