// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Button } from "@chakra-ui/react";

// const Pay = () => {
//   const [amt, setAmt] = useState(0);
//   const [orderId, setOrderId] = useState("");

//   useEffect(() => {
//     let user = JSON.parse(sessionStorage.getItem("userdetails"));

//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/total/${user?._id}`)
//       .then((res) => {
//         setAmt(res?.data?.data[0]?.total);
//       });
//   }, []);

//   const handlePayment = async () => {
//     try {

//       const {data} = await axios.post(
//         `${process.env.REACT_APP_API_BASE_URL}/api/orders`,
//         {
//           amount: amt,
//           currency: "INR",
//         }
//       );
//       setOrderId(data.id); // Set order ID from response

//       console.log(data);
//       // setOrderId(response.data.id); // Set order ID from response

//       if (!orderId) {
//         throw new Error("Failed to retrieve order ID. Please try again.");
//       }
//       const options = {
//         key: process.env.REACT_APP_RAZORPAY_KEY_ID,
//         amount: amt, // amount in the smallest currency unit (in paisa)
//         name: "JoyfulXperience",
//         currency: "INR",
//         description: "Payment for products",
//         image: "https://example.com/your_logo.png",
//         order_id: orderId,
//         prefill: {
//           name: "Customer Name",
//           email: "customer@example.com",
//           contact: "9999999999",
//         },
//         handler: function (response) {
//           alert(response.razorpay_payment_id);
//           alert(response.razorpay_order_id);
//           alert(response.razorpay_signature);
//         },
//         theme: {
//           color: "#F37254",
//         },
//       };

//       if (!window.Razorpay) {

//         console.error("Razorpay script not loaded");
//         return;
//       }

//       // Your existing code for fetching order ID and creating options

//       // Initialize Razorpay
//       const rzp1 = new window.Razorpay(options);
//       rzp1.open();
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <>
//       <div style={{ width: "100%" }}>
//         <Button
//           bg={"red"}
//           color={"white"}
//           _hover={{ color: "black" }}
//           onClick={handlePayment}
//         >
//           Proceed to Pay {amt}
//         </Button>
//       </div>
//     </>
//   );
// };

// export default Pay;
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "@chakra-ui/react";

const Pay = () => {
  const [amt, setAmt] = useState(0);
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("userdetails"));

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/total/${user?._id}`)
      .then((res) => {
        setAmt(res?.data?.data[0]?.total);
      });
  }, []);

  const handlePayment = async () => {

    try {

      const userDetails = JSON.parse(sessionStorage.getItem("userdetails"));
    
      // Check if user details exist
      if (!userDetails || !userDetails._id) {
        throw new Error("User details not found or invalid");
      }

      // Create an order on the backend
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/payment/orders`,
        {
          amount: amt,
          currency: "INR",
          receipt: "order_receipt_" + Date.now(), // You may customize the receipt ID
        }
      );
      const orderId = data.id;

      // Set order ID from response

      // Initialize Razorpay options
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: data.amount, // amount in the smallest currency unit (in paisa)
        name: "JoyfulXperience",
        currency: "INR",
        description: "Payment for products",
        image: "https://example.com/your_logo.png",
        order_id: orderId, // Use data.id directly here
        // callback_url: `${process.env.REACT_APP_API_BASE_URL}/api/verify`, // Redirect URL after successful payment
        prefill: {
          name: userDetails.name,
          email: userDetails.email,
          contact: "9999999999",
        },
        handler: function (response) {
          // Send payment details to the backend for verification
          console.log(userDetails._id)
          axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/payment/verify`, {
              orderId: response.razorpay_order_id,
              paymentId: response.razorpay_payment_id,
              signature: response.razorpay_signature,
              userId: userDetails._id,
            })
            .then((res) => {
              // Handle payment verification response
              alert(res.data.message);
            })
            .catch((err) => {
              console.error(err);
              alert("Payment verification failed");
            });
        },
        theme: {
          color: "#F37254",
        },
      };

      // Initialize Razorpay
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={handlePayment}
        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700 mt-2"
      >
        Proceed to Pay {amt}
      </button>
    </>
  );
};

export default Pay;
